import Link from 'next/link'
import { MapIcon } from '@heroicons/react/outline'
import { Layout } from '@components/common'

const PageNotFound = () => (
  <div className="px-4 pt-12 sm:px-8 lg:px-12 lg:pt-20">
    <div className="mx-auto flex w-full max-w-7xl flex-col lg:flex-row lg:items-end lg:justify-between">
      <div className="mx-auto flex max-w-2xl flex-col items-center justify-center pb-32 pt-24 text-center lg:pb-48">
        <div className="relative ml-3 mb-6 h-8 w-8 rounded-full bg-primary-100">
          <MapIcon className="absolute -left-4 -top-2 mb-6 h-10 w-10 text-primary-500" />
        </div>
        <span className="text-sm font-bold uppercase tracking-widest text-slate-600">
          404 error
        </span>
        <h1 className="mt-3 text-4xl font-bold text-primary-500 md:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base text-slate-500 lg:text-lg">
          Sorry, we couldn&apos;t find the page you&apos;re looking for.
        </p>
        <div className="mt-8">
          <Link href="/">
            <a className="font-semibold text-slate-500">Return to homepage</a>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

PageNotFound.Layout = Layout

export default PageNotFound
